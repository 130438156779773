import cn from 'classnames';

import TextRenderer from '@components/TextRenderer';

import { BasePrimaryHero } from '../PrimaryHero';
import Form from './Form';

import AnimateIn from '@components/AnimateIn';
import * as styles from './CampaignHeroHubSpotForm.module.css';
import textStyles from '@styles/textStyles.module.css';

export const CampaignHeroWithHubSpotForm = ({
  name,
  theme,
  eyebrow,
  heading,
  actions,
  backgroundIllustration,
  gradient,
  markerColor,
  text,
  instructions,
  listItems,
  form,
  clientLogos,
}) => {
  const textElement = (
    <TextRenderer
      text={text}
      as="p"
      className={cn(styles.text, textStyles.bodyXL)}
    />
  );

  return (
    <BasePrimaryHero
      name={name}
      eyebrow={eyebrow}
      heading={heading}
      text={textElement}
      actions={actions}
      backgroundIllustration={backgroundIllustration}
      gradient={gradient}
      theme={theme}
      markerColor={markerColor}
      className={styles.hero}
      mediaClassName={styles.formMedia}
      headingSize="xlarge"
      clientLogos={clientLogos}
    >
      <div className={styles.form}>
        <AnimateIn>
          <Form form={form} instructions={instructions} listItems={listItems} />
        </AnimateIn>
      </div>
    </BasePrimaryHero>
  );
};
